import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import React from 'react'

const Body1 = styled(Typography)`
    
    font-family: ${props => props.fm ? props.fm : "OpenSans"};
    text-align: ${props => props.ta ? props.ta : "center"};
    font-size: ${props => props.fs ? props.fs : "12px"};
    margin: ${props => props.m};
    @media(max-width:599px){
      font-size: ${props => props.fs599};
    }
`


//---------------//
const SectionSubHeading = (props) => {
  const {children} = props
  return (
    <Body1 variant='body1' color="#fff" {...props}>
    {children}
    </Body1>
  )
}

export default SectionSubHeading