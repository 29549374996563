import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { FetchDeleteData, FetchGetData, GetData } from "../FetchData";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import styled from "@emotion/styled";
import { Button, Grid } from "@mui/material";
import { SectionHeading } from "../../Styles/style";
import EditProjectData from "../form";
import CustomButton from "../button";
const DeleteGrid = styled(Grid)`
  min-height: calc(100vh - 500px);
  background-color: gray;
  /* opacity: 0.6; */
  display: flex;
  justify-content: center;
  align-items: center;
`;
const columns = [
  { id: "order_id", label: "Order ID", minWidth: 100 },
  { id: "name", label: "Trx", minWidth: 100 },
  // { id: "card_amount", label: "Card_Amount", minWidth: 100 },
  // { id: "pef_currency", label: "Pef_Currency", minWidth: 100 },
  // { id: "email", label: "Email", minWidth: 100 },
  { id: "user_name", label: "Send Currency", minWidth: 100 },
  // { id: "home_address", label: "Home_Address", minWidth: 100 },
  // { id: "country", label: "Country", minWidth: 100 },
  { id: "address", label: "withdrawal Address", minWidth: 100 },
  { id: "amount", label: "Send Amount", minWidth: 100 },
  // { id: "card_number", label: "Card_Number", minWidth: 100 },
  // { id: "cvc", label: "CVC", minWidth: 100 },
  { id: "status", label: "Status", minWidth: 100 },
  // { id: "order_token", label: "Order_Token", minWidth: 100 },
  { id: "card_status", label: "Payment_Status", minWidth: 100 },
  { id: "expiry_date", label: "Expiry_Date", minWidth: 100 },
];

function createData(
  order_id,
  name,
  address,
  amount,
  // card_number,
  // cvc,
  status,
  // order_token,
  card_status,
  expiry_date,
  // card_amount,
  // pef_currency,
  // email,
  user_name,
  // home_address,
  // country,
) {
  return {
    order_id,
    name,
    address,
    amount,
    // card_number,
    // cvc,
    status,
    // order_token,
    card_status,
    expiry_date,
    // card_amount,
    // pef_currency,
    // email,
    user_name,
    // home_address,
    // country,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DataTable() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [page, setPage] = React.useState(0);
  const [DeleteTriger, setDeleteTriger] = React.useState(true);
  const [pendingrows, setPendingRows] = React.useState([]);
  const [generatingrows, setGeneratingRows] = React.useState([]);
  const [generatedrows, setGeneratedRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pageType, setPageType] = React.useState("");
  const [rowData, setrowData] = React.useState("");
  const [isAlertVisible, setIsAlertVisible] = React.useState(false);

  const handelSubmitDelete = async () => {
    const endPoint = "deleteDataById";
    const method = "DELETE";
    const requestData = { orderId: rowData.order_id };
    const result = await FetchDeleteData(endPoint, requestData, method);
    if (result?.message === "succes") {
      setDeleteTriger(!DeleteTriger);
      setPageType("");
    } else {
      return false;
    }
  };
  const handleCancelBtnClick = (orderId) => {
    setPageType("");
  };
  const handleEditbtnClick = (row) => {
    setrowData(row);
    setPageType("editProject");
  };
  const handleDeleteBtnClick = (row) => {
    setrowData(row);
    setPageType("deleteProject");
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleFetchData = async () => {
    const endPoint = "getData";
    const method = "GET";
    const result = await GetData(endPoint, method);
    if (result?.message === "succes") {
      // console.log(result.data)
      const responseData = result.data;
      console.log("responsesss", result.data);
      const newPendingData = responseData.filter((row) => {
        if (row?.status === "NEW") {
          return createData(
            row?.order_id,
            row?.name,
            row?.address,
            row?.amount,
            // row?.card_number,
            // row?.cvc,
            row?.status,
            // row?.order_token,
            row?.card_status,
            row?.expiry_date, 
            // row?.card_amount,
            // row?.pef_currency,
            // row?.email,
            row?.user_name,
            // row.home_address,
            // row?.country,
          );
        } else {
          return false;
        }
      });
      const newGeneratingData = responseData.filter((row) => {
        if (row?.status === "DONE" && row?.card_status !== "DONE") {
          return createData(
            row?.order_id,
            row?.name,
            row?.address,
            row?.amount,
            // row?.card_number,
            // row?.cvc,
            row?.status,
            // row?.order_token,
            row?.card_status,
            row?.expiry_date,
            // row?.card_amount,
            // row?.pef_currency,
            // row?.email,
            row?.user_name,
            // row.home_address,
            // row?.country,
          );
        } else {
          return false;
        }
      });
      const newGeneratedData = responseData.filter((row) => {
        if (row?.status === "DONE" && row?.card_status === "DONE") {
          return createData(
            row?.order_id,
            row?.name,
            row?.address,
            row?.amount,
            // row?.card_number,
            // row?.cvc,
            row?.status,
            // row?.order_token,
            row?.card_status,
            row?.expiry_date,
            // row?.card_amount,
            // row?.pef_currency,
            // row?.email,
            row?.user_name,
            // row.home_address,
            // row?.country,
          );
        } else {
          return false;
        }
      });
      setPendingRows(newPendingData);
      setGeneratingRows(newGeneratingData);
      setGeneratedRows(newGeneratedData);
    } else {
      return false;
    }
  };
  React.useEffect(() => {
    console.log("here");
    handleFetchData();
  }, [DeleteTriger]);

  return (
    <>
      {pageType === "editProject" ? (
        <EditProjectData
          setPageType={setPageType}
          rowData={rowData}
          DeleteTriger={DeleteTriger}
          setDeleteTriger={setDeleteTriger}
        />
      ) : pageType === "deleteProject" ? (
        <>
          <DeleteGrid item xs={12}>
            {isAlertVisible && (
              <div className="alert-container">
                +{" "}
                <div style={{ transition: "1s" }} className="alert-inner">
                  Project Deleted
                </div>
                +{" "}
              </div>
            )}
            <div>
              <SectionHeading
                fs="20px"
                ta="center"
                Dis="none"
                m_after="10px 0"
                m="10px 0"
              >
                DELETE PROJECT ?
              </SectionHeading>
              <div style={{ display: "flex", gap: "30px" }}>
                <Button
                  variant="filled"
                  onClick={handleCancelBtnClick}
                  sx={{
                    background: "blue",
                    color: "#fff",
                    border: "1px solid blue",
                    borderRadius: "2px !important",
                  }}
                  p_hover="8px 35px"
                  b="1px solid var(--primary-color)"
                  fc="var(--primary-color)"
                  bgc="#fff"
                >
                  CANCEL
                </Button>
                <Button
                  variant="filled"
                  onClick={handelSubmitDelete}
                  sx={{
                    background: "red",
                    color: "#fff",
                    border: "1px solid red",
                    borderRadius: "2px !important",
                  }}
                  p_hover="8px 35px"
                  b="1px solid red"
                  fc="red"
                  bgc="#fff"
                >
                  DELETE
                </Button>
              </div>
            </div>
          </DeleteGrid>
        </>
      ) : (
        <>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{ borderBottom: 1, width: "100%", borderColor: "divider" }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{ width: "100% !important" }}
              >
                <Tab sx={{ width: "100%" }} label="Pending" {...a11yProps(0)} />
                <Tab
                  sx={{ width: "100%" }}
                  label="Generating"
                  {...a11yProps(1)}
                />
                <Tab
                  sx={{ width: "100%" }}
                  label="Generated"
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Paper
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  minHeight: "calc(100vh - 250px)",
                }}
              >
                <TableContainer sx={{ minHeight: "calc(100vh - 250px)" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                        <TableCell
                          key="actions"
                          align="left"
                          style={{ minWidth: 100 }}
                        >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pendingrows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row?.code}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                              <CustomButton
                                variant="disabled"
                                bgc="#F9860B  !important"
                                sx={{
                                  width: "150px",
                                  borderRadius: "2px !important",
                                }}
                              >
                                Pending Trx
                              </CustomButton>
                              {/* <Tooltip title="Edit">
                          <EditNoteIcon
                            sx={{
                              fontSize: "30px",
                              color: "var(--primary-color)",
                              margin: "0 10px",
                            }}
                            onClick={() => handleEditbtnClick(row)}
                          />
                        </Tooltip> */}
                              {/* <Tooltip title="Delete">
                          <DeleteIcon
                            sx={{
                              fontSize: "30px",
                              color: "red",
                              margin: "0 10px",
                            }}
                            onClick={() => handleDeleteBtnClick(row)}
                          />
                        </Tooltip> */}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={pendingrows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
            <Paper
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  minHeight: "calc(100vh - 250px)",
                }}
              >
                <TableContainer sx={{ minHeight: "calc(100vh - 250px)" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                        <TableCell
                          key="actions"
                          align="left"
                          style={{ minWidth: 100 }}
                        >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {generatingrows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row?.code}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                              <CustomButton
                                variant="contained"
                                onClick={() => handleEditbtnClick(row)}
                                bgc="#02348D"
                                sx={{
                                  width: "150px",
                                  borderRadius: "2px !important",
                                }}
                              >
                                Sending Payment
                              </CustomButton>
                              {/* <Tooltip title="Edit">
                          <EditNoteIcon
                            sx={{
                              fontSize: "30px",
                              color: "var(--primary-color)",
                              margin: "0 10px",
                            }}
                            onClick={() => handleEditbtnClick(row)}
                          />
                        </Tooltip> */}
                              {/* <Tooltip title="Delete">
                          <DeleteIcon
                            sx={{
                              fontSize: "30px",
                              color: "red",
                              margin: "0 10px",
                            }}
                            onClick={() => handleDeleteBtnClick(row)}
                          />
                        </Tooltip> */}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={generatingrows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
            <Paper
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  minHeight: "calc(100vh - 250px)",
                }}
              >
                <TableContainer sx={{ minHeight: "calc(100vh - 250px)" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                        <TableCell
                          key="actions"
                          align="left"
                          style={{ minWidth: 100 }}
                        >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {generatedrows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row?.code}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                              <CustomButton
                                variant="disabled"
                                bgc="#02348D"
                                sx={{
                                  width: "150px",
                                  borderRadius: "2px !important",
                                }}
                              >
                                sent
                              </CustomButton>
                              {/* <Tooltip title="Edit">
                          <EditNoteIcon
                            sx={{
                              fontSize: "30px",
                              color: "var(--primary-color)",
                              margin: "0 10px",
                            }}
                            onClick={() => handleEditbtnClick(row)}
                          />
                        </Tooltip> */}
                              {/* <Tooltip title="Delete">
                          <DeleteIcon
                            sx={{
                              fontSize: "30px",
                              color: "red",
                              margin: "0 10px",
                            }}
                            onClick={() => handleDeleteBtnClick(row)}
                          />
                        </Tooltip> */}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={generatedrows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </CustomTabPanel>
          </Box>
        </>
      )}
    </>
  );
}
