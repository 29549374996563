import { ThemeProvider, createTheme } from "@mui/material";
import { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { useSelector } from "react-redux";
import MainPage from "../Views/Pages/Main";
import LoginPageView from "../Views/Pages/login";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

const getActiveTheme = (themeMode) => {
  console.log("themeMode: ", themeMode);
  return createTheme({
    pageColor: "#000",

    bgPrimary: "#000",
    bgSecondary: "#0C0B0B",

    textPrimary: "#fff",
    textSecondary: "#3B5CFF",

    switchColor: "#b1b1b1",
  });
};
const ThemeContainer = () => {
  const currentTheme = useSelector((state) => state.LDTheme.value);
  const [activeTheme, setActiveTheme] = useState(getActiveTheme(currentTheme));

  useEffect(() => {
    setActiveTheme(getActiveTheme(currentTheme));
  }, [currentTheme]);
  return (
    <ThemeProvider theme={activeTheme}>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPageView />} />
          <Route path="/dashboard" element={<MainPage />} />

          {/* Add a catch-all route to handle 404 errors */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default ThemeContainer;
