import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import React, { useContext, useState } from "react";
// import { context } from "@/app/view/Dashboard/portfolio";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import SectionSubHeading from "../typography/sectionSubHeading";
import CustomButton from "../button";
import { FetchUpdateData } from "../FetchData";
import { InputField } from "../../Styles/style";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
const ImageDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  border: 1px solid #d7d7d7;
  border-radius: 2px;
`;
const Form = styled(FormControl)`
  width: 80%;
  border: 1px solid #d7d7d7;
  border-radius: 0px !important;
  padding: 2px 10px;
  :focus {
    outline: 1px solid var(--primary-color);
  }
  @media (max-width: 599px) {
    width: 100%;
  }
`;
const Label = styled.label`
  width: 80%;
  @media (max-width: 599px) {
    width: 100%;
  }
`;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 10px;
  @media (max-width: 599px) {
    flex-direction: ${(props) => (props.fd ? props.fd : "row")};
    align-items: flex-start;
  }
`;
const EditProjectData = (props) => {
  const { rowData, setPageType,setDeleteTriger,DeleteTriger} = props;
  const [Name, setName] = useState(rowData.name);
  const [Amount, setAmount] = useState(rowData.amount);
  const [HomeAddress, setHomeAddress] = useState(rowData.home_address);
  const [CardNumber, setCardNumber] = useState(rowData.card_number);
  const [CVC, setCVC] = useState(rowData.cvc);
  const [Status, setStatus] = useState(rowData.status);
  const [CardStatus, setCardStatus] = useState(rowData.card_status);
  const [ExpiryDate, setExpiryDate] = useState(rowData.expiry_date);
  const [isAlertVisible, setIsAlertVisible] = React.useState(false);
  const handelNameInput = (value) => {
    setName(value);
  };
  const handelAmountInput = (value) => {
    setAmount(value);
  };
  const handelHomeAddressInput = (value) => {
    setHomeAddress(value);
  };
  const handelCardNumberInput = (value) => {
    setCardNumber(value);
  };
  const handelCVCInput = (value) => {
    setCVC(value);
  };
  const handelStatusInput = (value) => {
    setStatus(value);
  };
  const handelCardStatusInput = (value) => {
    setCardStatus(value);
  };
  const handelExpiryDateInput = (value) => {
    setExpiryDate(value);
  };

  const handelSubmitUpdate = async () => {
    const endPoint = "updateData";
    const requestData = {
      orderId: rowData.order_id,
      name: Name,
      address: rowData.address,
      homeAddress: HomeAddress,
      amount: Amount,
      cardNumber: CardNumber,
      cvc: CVC,
      status: Status,
      orderToken: rowData.order_token,
      cardStatus: CardStatus,
      expDate: ExpiryDate,
    };
    const method = "PUT";
    const result = await FetchUpdateData(endPoint, requestData, method);
    if (result?.message === "updated") {
      setDeleteTriger(!DeleteTriger);
      toast.success("Updated");
      setPageType("");
    }
  };
  return (
    <>
      <Grid container>
      {isAlertVisible && (
              <div className="alert-container">
                +{" "}
                <div style={{ transition: "1s" }} className="alert-inner">
                  Project Deleted
                </div>
                +{" "}
              </div>
            )}
        {/* <Grid item xs={12}>
          <InputDiv fd="column">
            <SectionSubHeading color="#000" fm="OpenSansBold" fs="16px">
              Name
            </SectionSubHeading>
            <InputField
              w="80%"
              w599="100%"
              placeholder="Name"
              value={Name}
              type="text"
              onChange={(e) => handelNameInput(e.target.value)}
            />
          </InputDiv>
        </Grid>
        <Grid item xs={12}>
          <InputDiv fd="column">
            <SectionSubHeading color="#000" fm="OpenSansBold" fs="16px">
              Amount
            </SectionSubHeading>
            <InputField
              w="80%"
              w599="100%"
              placeholder="Amount"
              value={Amount}
              onChange={(e) => handelAmountInput(e.target.value)}
            />
          </InputDiv>
        </Grid>
        <Grid item xs={12}>
          <InputDiv fd="column">
            <SectionSubHeading color="#000" fm="OpenSansBold" fs="16px">
              Home Address
            </SectionSubHeading>
            <InputField
              w="80%"
              w599="100%"
              placeholder="Home Address"
              value={HomeAddress}
              onChange={(e) => handelHomeAddressInput(e.target.value)}
            />
          </InputDiv>
        </Grid>
        <Grid item xs={12}>
          <InputDiv fd="column">
            <SectionSubHeading color="#000" fm="OpenSansBold" fs="16px">
              Card Number
            </SectionSubHeading>
            <InputField
              w="80%"
              w599="100%"
              placeholder="CardNumber"
              value={CardNumber}
              onChange={(e) => handelCardNumberInput(e.target.value)}
            />
          </InputDiv>
        </Grid>
        <Grid item xs={12}>
          <InputDiv fd="column">
            <SectionSubHeading color="#000" fm="OpenSansBold" fs="16px">
              CVC
            </SectionSubHeading>
            <InputField
              w="80%"
              w599="100%"
              placeholder="CVC"
              value={CVC}
              onChange={(e) => handelCVCInput(e.target.value)}
            />
          </InputDiv>
        </Grid>
        <Grid item xs={12}>
          <InputDiv fd="column">
            <SectionSubHeading color="#000" fm="OpenSansBold" fs="16px">
              Status
            </SectionSubHeading>
            <Form fullWidth>
              <NativeSelect
                defaultValue={Status}
                onChange={(e) => handelStatusInput(e.target.value)}
                inputProps={{
                  name: "category",
                  id: "uncontrolled-native",
                }}
              >
                <option value="NEW">NEW</option>
                <option value="PENDING">PENDING</option>
                <option value="DONE">DONE</option>
              </NativeSelect>
            </Form>
          </InputDiv>
        </Grid> */}
        <Grid item xs={12}>
          <InputDiv fd="column">
            <SectionSubHeading color="#000" fm="OpenSansBold" fs="16px">
              Payment Status
            </SectionSubHeading>
            <Form fullWidth>
              <NativeSelect
                defaultValue={CardStatus}
                onChange={(e) => handelCardStatusInput(e.target.value)}
                inputProps={{
                  name: "category",
                  id: "uncontrolled-native",
                }}
              >
                <option value="NEW">NEW</option>
                <option value="PENDING">PENDING</option>
                <option value="DONE">DONE</option>
              </NativeSelect>
            </Form>
            {/* <InputField w="80%" placeholder="Project Category" value={projectCategory}  onChange={(e) => handelCategoryInput(e.target.value)}/> */}
          </InputDiv>
        </Grid>
        {/* <Grid item xs={12}>
          <InputDiv fd="column">
            <SectionSubHeading color="#000" fm="OpenSansBold" fs="16px">
              Expiry Date
            </SectionSubHeading>
            <InputField
              w="80%"
              w599="100%"
              placeholder="ExpiryDate"
              value={ExpiryDate}
              onChange={(e) => handelExpiryDateInput(e.target.value)}
            />
          </InputDiv>
        </Grid> */}
        <Grid item xs={12}>
          <InputDiv>
            <CustomButton
              onClick={() => setPageType("")}
              sx={{ width: "49%", borderRadius: "2px !important" }}
              p_hover="8px 35px"
            >
              CANCEL
            </CustomButton>
            <CustomButton
              onClick={handelSubmitUpdate}
              sx={{ width: "49%", borderRadius: "2px !important" }}
              p_hover="8px 35px"
            >
              UPDATE
            </CustomButton>
          </InputDiv>
        </Grid>
      </Grid>
    </>
  );
};

export default EditProjectData;
