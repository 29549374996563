import React from "react";
import "./style.js";
import { Lable } from "./style.js";
import styled from "@emotion/styled";
const InputField = styled.input`
  font-family: OpenSans;
  padding: 10px;
  background-color: #fff;
  color: #000;
  width: ${props => props.w ? props.w :"49%"};
  height: 20px;
  border: 1px solid #d7d7d7;
  border-radius: ${props=> props.br ? props.br : "0px"};
  :focus {
    outline: 1px solid var(--primary-color);
  }
  @media(max-width : 599px){
    flex-direction: ${props=> props.fd ? props.fd : "row"};
    align-items: flex-start;
    width: ${props => props.w599};
  }
`;
const P = styled.p`
  
  color: red;
  font-size: 10px;

`
const Form = ({
  text,
  value,
  className,
  type,
  validEmail,
  value1,
  doubleEmailCheck,
  nameCheck,
  emailCheck,
  passCheck,
  yearCheck,
  colorCheck,
  modelCheck,
  emailStatus,
  passStatus,
  onChange,
  placeHolder,
  yearStatus,
  min,
  max,
}) =>  {
  return (
    <form className={className} style={{margin:"10px 0"}}>
      <Lable>{text}</Lable>
      <InputField
        br="10px"
        w="100%"
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeHolder}
        min={min}
        max={max}
        required={true}
        
      />
      {!value && !value1 ? (
        <P className={"showP"}>please fill this field</P>
      ) : emailCheck === "false" ?(
        <P className={"showP"}>please fill this field</P>
      ): passCheck === "false" ?(
        <P className={"showP"}>please fill this field</P>
      ): modelCheck === "false" ?(
        <P className={"showP"}>please fill this field</P>
      ): colorCheck === "false" ?(
        <P className={"showP"}>please fill this field</P>
      ): yearCheck === "false" ?(
        <P className={"showP"}>please fill this field</P>
      ) : nameCheck === "false" ?(
        <P className={"showP"}>please fill this field</P>
      ) : doubleEmailCheck === "false" ?(
        <P className={"showP"}>Email Already Used</P>
      ) : !value1 ? (
        <P className={"showP"}>cannot put integer</P>
      ) : emailStatus === "false" ? (
        <P className={"showP"}>Email not valid</P>
      ) : passStatus === "false" ? (
        <P className={"showP"}>Password not valid</P>
      ): yearStatus === "false" ? (
        <P className={"showP"}>Year range is 1980 to 2023</P>
      ) :validEmail === "false" ? (
        <P className={"showP"}>Email Formate(example@gmail.com)</P>
      ) : (
        <></>
      )}
    </form>
  );
}

export default Form;
