import React, { useEffect, useRef, useState } from "react";
import { AES } from "crypto-js";
import { Container, Typography } from "@mui/material";
import styled from "@emotion/styled";

import Cookies from "js-cookie";
import Form from "../../Components/InputForm";
import CustomButton from "../../Components/button";
import { FetchPostData } from "../../Components/FetchData";
import { DataImage } from "../../Styles/style";

import logo from "../../../assets/images/logo.png"
import { WEBSITE_URL } from "../../Constant";

const Div = styled.div`
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 100%;
  text-align: center;
  padding: 0 30px 30px;
  border-radius: 20px;
  margin: 0;
  top: 50%;
  color: #000;
`;
const Img = styled.img`
  width: 100%;
  max-width: ${(props) => props.mw};
  margin-top: ${(props) => props.m};
  padding: 10px 0;
  //
`;
const ContainerWraper = styled(Container)`
  position: relative;
  overflow: hidden;
  animation: background-gradient 10s;
  animation-iteration-count: infinite;
  backface-visibility: hidden;
  animation-timing-function: ease-in-out;
  background-image: radial-gradient(circle at 50% 50%, transparent, #8598b1);
`;
const Divinput = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
`;
const LoginPageView = ({}) => {

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  // const router = useRouter()
  const [email, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailStatus, setEmailStatus] = useState("true");
  const [passStatus, setPassStatus] = useState("true");
  const [value1, setValue1] = useState("true");
  const [value2, setValue2] = useState("true");
  const [emailCheck, setEmailCheck] = useState("true");
  const [passCheck, setPassCheck] = useState("true");
  const [count, setCount] = useState(0);
  const inputRef = useRef(null);
  const handleNameChange = (event) => {
    if (event.key === "Enter") {
      console.log("here");
      event.preventDefault();
      input2Ref.current.focus();
    } else {
      // setValidatePass("hide");
      setUserEmail(event.target.value);
    }
   
    // console.log(email)
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  useEffect(() => {
    if (email === "" && count !== 0) {
      setValue1("");
      setEmailStatus("true");
    } else {
      setEmailStatus("true");
      setEmailCheck("true");
      setValue1("true");
    }
    setCount(2);
  }, [email]);
  useEffect(() => {
    if (password === "" && count !== 0) {
      setValue2("");
      setPassStatus("true");
    } else {
      setPassStatus("true");
      setPassCheck("true");
      setValue2("true");
    }
    setCount(2);
  }, [password]);
  const handleSubmitLogin = async () => {
    const requestData = {
      email,
      password,
    };
    // console.log(requestData)
    const endPoint = "login";
    const method = "POST";
    const result = await FetchPostData(endPoint, requestData, method);
    if (result?.status === "succes") {
      console.log("user loged in",result.user_id);
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + 60 * 60 * 1000);
      const encryptionKey = "f99e0578f118d0e9d1a81b7f75533b"; // Replace this with your secret key
      const encryptedUser = AES.encrypt(
        result.user_id,
        encryptionKey
      ).toString();
      //console.log("encryptedUser",encryptedUser);
      Cookies.set("user", encryptedUser, { expires: expirationDate });
      window.location.replace(`${WEBSITE_URL}dashboard`);
    } else if (result?.status === "All fields are required") {
      // setUserEmail("");
      // setPassword("");
      setEmailCheck("false");
      setPassCheck("false");
    } else if (result?.status === "Email is required") {
      // setUserEmail("");
      setEmailCheck("false");
    } else if (result?.status === "Password is required") {
      // setPassword("");
      setPassCheck("false");
    } else if (result?.status === "Email is not valid") {
      setEmailStatus("false");
    } else if (result?.status === "Password is not valid") {
      setPassStatus("false");
    } else {
      console.log(result?.status);
    }
  };
  const handleInputChange = (event) => {
    if (event.key === "Enter") {
      console.log("here");
      event.preventDefault(); // Prevents form submission if the input is inside a form element
      inputRef.current.blur(); // Remove focus from the input
      handleSubmitLogin(); // Triggers the button click event
    } else {
      // setValidatePass("hide");
      setPassword(event.target.value);
    }
  };
  

  return (
    <>
      <ContainerWraper maxWidth="xxl">
        <Container
          maxWidth="xs"
          sx={{ height: "100vh", display: "grid", placeItems: "center" }}
        >
          <Div style={{ alignItems: "center" }}>
            <DataImage mw="100px" src={logo} />
            <Divinput>
              <Form
                ref={input1Ref}
                text={"Enter User email"}
                value={email}
                type={"text"}
                value1={value1}
                emailCheck={emailCheck}
                emailStatus={emailStatus}
                placeHolder={"Enter user email"}
                onChange={handleNameChange}
              />
              <Form
                ref={input2Ref}
                text={"Enter Password"}
                value={password}
                type={"password"}
                passCheck={passCheck}
                r
                value1={value2}
                passStatus={passStatus}
                placeHolder={"Enter password"}
                onChange={handleInputChange}
              />
              <CustomButton
                sx={{ width: "100%" }}
                m="20px 0 0"
                onClick={handleSubmitLogin}
              >
                LOGIN
              </CustomButton>
            </Divinput>
          </Div>
        </Container>
      </ContainerWraper>
    </>
  );
};

export default LoginPageView;
