
const { default: styled } = require("@emotion/styled");


const Lable = styled.label`
    color: rgb(105, 105, 105);
    display: flex;
    font-size: large;
    font-weight:bold;
    /* margin-bottom: 0rem; */
`



export {Lable}