
import { AES, enc } from 'crypto-js'
import Cookies from 'js-cookie'
import { FetchGetData } from '../FetchData';
const handleSubmitLogin = async () => {
    const userdata = Cookies.get("user")
    
    if(userdata){
      //console.log("userdata",userdata);
      const encryptionKey = "f99e0578f118d0e9d1a81b7f75533b" // Replace this with your secret key
      const decryptedText = AES.decrypt(userdata, encryptionKey).toString(enc.Utf8);
      //console.log("decryptedText",decryptedText);
      const queryString = `?_id=${decryptedText}`
      const endPoint = "getValidUser";
      const method = "GET";
      const result = await FetchGetData(endPoint, queryString, method);
      if (result?.status === "authorized") {
        return true;
      }else{
        return false
      }
    }else{
      return false
    }
  };

  export default handleSubmitLogin