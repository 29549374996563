import styled from '@emotion/styled'
import { Button } from '@mui/material'
import React from 'react'

const StyledButton = styled(Button)`
    padding: ${props => props.p ? props.p : "5px 5px"};
    text-align: center;
    background-color: ${props=> props.bgc ? props.bgc : "#02348D"};
    color: ${props=> props.fc ? props.fc : "#ffffff"};
        /* border: ${props=> props.bgc ? `1px solid ${props.bgc}` : "1px solid #02348D"}; */
    font-family: OpenSansBold;
    font-size: 14px;
    border-radius: 10px;
    margin: ${props=> props.m ? props.m : "0px"};
    box-shadow: none;
    transition: .5s !important;
    :hover{
    background-color: ${props=> props.bgc ? props.bgc : "#02348D"};
    color: ${props=> props.fc ? props.fc : "#ffffff"};
        /* border: ${props=> props.bgc ? `1px solid ${props.bgc}` : "1px solid #02348D"}; */
    }
    @media (max-width:599px){
      font-size:10px;
    }
    
`
const CustomButton = (props) => {
  const {anim} = props
  return (
    <StyledButton variant="contained" {...props} >
    </StyledButton>
  )
}

export default CustomButton