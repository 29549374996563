import styled from "@emotion/styled";

const {
  AppBar,
  Grid,
  IconButton,
  ListItemText,
  Drawer,
  List,
} = require("@mui/material");

const DashBoardNavBar = styled(AppBar)`
  background-color: #fff;
  color: #000;
  font-family: OpenSansBold;
  text-transform: none;
  font-size: 14px;
  z-index: 1201;
  background-color: white;
  margin: 0 5px;
  display: flex;
`;
const DivWrap = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 599px) {
    display: flex;
    flex-direction: row;
    height: auto !important;
  }
`;
const IconWraper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  @media(max-width : 599px){
    padding: 0 20px;
  }
`;

const IconButtonOpen = styled(IconButton)`
  @media (max-width: 599px) {
    display: none;
  }
`;
const ListItemsText = styled(ListItemText)`
  @media (max-width: 599px) {
    display: none;
  }
`;
const MobileDrawer = styled(Drawer)`
  @media (max-width: 599px) {
    width: 0;
    height: auto;
    & .MuiDrawer-paper {
        top: auto !important;
        bottom: 0 !important;
        height: auto !important;
    }
  }
`;
const UList = styled(List)`
  @media (max-width: 599px) {
    display: flex;
    flex-direction: row;
    height: auto;
    padding: 0 0 0 10px;
  }
`;

export {
  DashBoardNavBar,
  DivWrap,
  IconWraper,
  IconButtonOpen,
  ListItemsText,
  MobileDrawer,
  UList,
};
